import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import IntroArea from "@components/introarea/layout-1";
import TechDocuments from "@containers/technical-translation/technical-documents";
import CaseStudy from "@containers/global/case-study";
import { caseStudiesData } from "@constants";
import FaqArea from "@containers/faq/layout-03";
import ContactArea from "@containers/contact-us";

const TechnicalTranslationPage = ({ location, data }) => {
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);

  return (
    <Layout location={location}>
      <Seo title="High Quality Technical Translation Services by Andovar" description="Market your technical, scientific, and business content to prospects in any country with the technical translation services by Andovar. Contact us!" />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
        }}
      />
      <main className="site-wrapper-reveal">
        <PageHeader
          data={content["technical-translation-header-section"]}
          pageContext={content["technical-translation-meta-description"]}
        />
        {/* <IntroArea data={content["technical-translation-intro"]} /> */}
        <IntroArea data={content["technical-translation-intro"]} />
        <TechDocuments data={content["technical-translation-documents"]} />

        <CaseStudy data={caseStudiesData} />
        <FaqArea data={content["technical-translation-faq-body"]} />
        <ContactArea Layout={2} />
      </main>
      <Footer data={{ ...data.site.siteMetadata }} />
    </Layout>
  );
};

export const query = graphql`
  query technicalTranslationPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(
      title: { eq: "technical-translation" }
      pageType: { eq: "innerpage" }
    ) {
      content {
        ...PageContent
      }
    }
  }
`;

TechnicalTranslationPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default TechnicalTranslationPage;
