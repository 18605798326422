import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import SectionTitle from "@ui/section-title";
import Heading from "@ui/text";
import Image from "@ui/image";
import {
  TechDocumentsWrapper,
  TechDocumentsInner,
  TechDocumentsBoxWrap,
  TechDocumentsBox,
  TechDocumentsIconwarp,
  TechDocumentsTextwarp,
} from "./style";

export default function TechDocuments({ headingStyle, data }) {
  return (
    <TechDocumentsWrapper>
      <TechDocumentsInner>
        <Container>
          <Row>
            <Col lg={12}>
              <SectionTitle
                subtitle={data.section_title.subtitle}
                title={data.section_title.title}
                description={data.section_title.description}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <TechDocumentsBoxWrap>
                {data.items.map((data, index) => (
                  <TechDocumentsBox key={index}>
                    <TechDocumentsIconwarp>
                      <Image src={data.images[0].src} alt="automated" />
                    </TechDocumentsIconwarp>
                    <TechDocumentsTextwarp>
                      <Heading {...headingStyle}>{data.title}</Heading>
                    </TechDocumentsTextwarp>
                  </TechDocumentsBox>
                ))}
              </TechDocumentsBoxWrap>
            </Col>
          </Row>
        </Container>
      </TechDocumentsInner>
    </TechDocumentsWrapper>
  );
}
TechDocuments.propTypes = {
  headingStyle: PropTypes.object,
};

TechDocuments.defaultProps = {
  headingStyle: {
    as: "h4",
    color: "#000",
    fontSize: "22px",
    fontweight: 700,
    m: 0,
  },
};
