import styled, { themeGet, device } from "@styled";

export const TechDocumentsWrapper = styled.section`
  padding: 100px 0;
  background: #f8f8f8;
`;
export const TechDocumentsInner = styled.div``;
export const TechDocumentsBoxWrap = styled.div`
  display: grid;
  grid-templaete-columns: repeat(1, 1fr);
  grid-gap: 10px;
  ${device.medium} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${device.large} {
    grid-template-columns: repeat(3, 1fr);
  }
`;
export const TechDocumentsBox = styled.div`
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #e6e6e6;
  padding: 24px 20px;
  background: #fff;
`;
export const TechDocumentsIconwarp = styled.div`
  min-width: 90px;
  width: 90px;
  height: 90px;
  margin-right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background: linear-gradient(
    320deg,
    ${themeGet("colors.secondaryDark")},
    ${themeGet("colors.secondary")}
  );
`;
export const TechDocumentsTextwarp = styled.div``;
